import Card from "components/Cards/Card.js";
import LoadingTableCard from "components/Cards/LoadingTableCard.js";
import Cell from "components/Table/Cell.js";
import HeadingCell from "components/Table/HeadingCell.js";
import RowHeadingCell from "components/Table/RowHeadingCell.js";
import Table from "components/Table/Table.js";
import TablePagination from "components/Table/TablePagination";
import { firestoreBase } from "constants.js";
import { format } from "date-fns/fp";
import PropTypes from "prop-types";
import React, { Suspense, useCallback, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useFirestore, useFirestoreCollection } from "reactfire";

function Transactions({ ...props }) {
  if (props.title == null) {
    props.title = "Transactions";
  }
  const headings = ["Trx. ID", "Amount", "Time"];
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <Suspense
            fallback={<LoadingTableCard {...props} headings={headings} />}
          >
            <TransactionsCard {...props} headings={headings} />
          </Suspense>
        </div>
      </div>
    </>
  );
}

Transactions.defaultProps = {
  title: "Transactions",
  numRows: 10,
};

Transactions.propTypes = {
  title: PropTypes.string.isRequired,
};

function TransactionsCard({ title, headings, numRows }) {
  const query = useFirestore()
    .doc(firestoreBase)
    .collection("transactions")
    .orderBy("time", "desc");
  const { data: collection } = useFirestoreCollection(query);

  const location = useLocation();
  const startPage =
    location.state?.page ?? (collection.docs.length > 0 ? 1 : 0);
  const [page, setPage] = useState(startPage);
  const numPages = Math.ceil(collection.docs.length / numRows);
  const nextPage = useCallback(
    () => setPage((page) => Math.min(numPages, page + 1)),
    [numPages]
  );
  const prevPage = useCallback(
    () => setPage((page) => Math.max(1, page - 1)),
    []
  );
  const goToPage = useCallback(
    (page) => setPage(() => Math.min(Math.max(1, page), numPages)),
    [numPages]
  );
  return (
    <Card
      title={title}
      action={
        <Link
          to="transactions/upload"
          role="button"
          className="py-2 px-4 rounded border-0 shadow uppercase font-bold text-xs text-white bg-violet-500 focus:outline-none hover:bg-violet-400 hover:shadow-md active:bg-violet-600"
        >
          Upload PDF
        </Link>
      }
    >
      <Table>
        <thead>
          <tr>
            {headings.map((heading) => (
              <HeadingCell key={heading}>{heading}</HeadingCell>
            ))}
          </tr>
        </thead>
        <tbody>
          {collection.docs
            .slice((page - 1) * numRows, page * numRows)
            .map((doc) => (
              <Transaction key={doc.id} doc={doc} />
            ))}
        </tbody>
      </Table>
      <hr />
      <TablePagination
        current={page}
        total={numPages}
        onNext={nextPage}
        onPrev={prevPage}
        onJump={goToPage}
      />
    </Card>
  );
}

function Transaction({ doc }) {
  const trx = doc.data();
  const formatDate = format("ccc dd LLL hh:mm a (O)");
  return (
    <tr>
      <RowHeadingCell>#{doc.id}</RowHeadingCell>
      <Cell>{trx.amount}</Cell>
      <Cell>{formatDate(trx.time.toDate())}</Cell>
    </tr>
  );
}

export default Transactions;
