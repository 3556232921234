import Card from "components/Cards/Card.js";
import { firestoreBase } from "constants.js";
import { useCallback, useState } from "react";
import Dropzone from "react-dropzone";
import { useFirestore, useStorage } from "reactfire";

function TransactionsUpload() {
  const storage = useStorage();
  const firestore = useFirestore();
  const FieldValue = useFirestore.FieldValue;
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const onUpload = useCallback(
    async (file) => {
      console.log(file);
      setUploading(true);
      try {
        const ref = firestore
          .doc(firestoreBase)
          .collection("transactionLogs")
          .doc();
        await storage.ref(`transaction-logs/${ref.id}.pdf`).put(file, {
          contentType: "application/pdf",
        });
        await ref.set({
          status: "new",
          uploadedAt: FieldValue.serverTimestamp(),
        });
        setUploaded(true);
      } finally {
        setUploading(false);
      }
    },
    [storage, firestore, FieldValue]
  );
  return (
    <Card title="mBoK Transactions PDF">
      <div className="p-8">
        {uploading ? (
          <Uploading />
        ) : uploaded ? (
          <Uploaded />
        ) : (
          <UploadPDF onUpload={onUpload} />
        )}
      </div>
    </Card>
  );
}

function UploadPDF({ onUpload }) {
  return (
    <Dropzone
      onDropAccepted={(files) => onUpload(files[0])}
      accept="application/pdf"
      multiple={false}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps({
            className:
              "flex flex-col gap-4 p-4 rounded-xl justify-center items-center border-dashed border-4 border-blueGray-400 text-blueGray-600",
          })}
        >
          <input {...getInputProps()} />
          <span className="text-2xl">
            Drag and drop the PDF file here or click
          </span>
          <i className="fas fa-cloud-upload-alt text-8xl text-blueGray-400" />
        </div>
      )}
    </Dropzone>
  );
}

function Uploading() {
  return (
    <div className="flex flex-col gap-4 p-4 justify-center items-center">
      <span className="text-2xl text-blueGray-600 animate-pulse">
        Uploading file
      </span>
      <i className="fas fa-circle-notch text-8xl text-blueGray-400 animate-spin-slow" />
    </div>
  );
}

function Uploaded() {
  return (
    <div className="flex flex-col gap-4 p-4 justify-center items-center">
      <span className="text-2xl text-emerald-600">File Uploaded</span>
      <i className="fas fa-check-circle text-8xl text-emerald-400" />
    </div>
  );
}

export default TransactionsUpload;
