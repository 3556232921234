import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/index.css";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import React from "react";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import {
  FirebaseAppProvider,
  preloadAuth,
  preloadFirestore,
  preloadStorage,
} from "reactfire";
import App from "./App.js";
import { fetchFirebaseConfig, setupEmulators } from "./firebaseConfig.js";

async function preloadSDKs(firebaseApp) {
  const preloadConfig = { firebaseApp, suspense: true };
  await Promise.all([
    preloadAuth(preloadConfig),
    preloadFirestore(preloadConfig),
    preloadStorage(preloadConfig),
  ]);
}
async function main() {
  try {
    const firebaseConfig = await fetchFirebaseConfig();
    const firebaseApp = firebase.initializeApp(firebaseConfig);
    await preloadSDKs(firebaseApp);
    setupEmulators(firebaseApp);
    return ReactDOM.render(
      <FirebaseAppProvider firebaseApp={firebaseApp} suspense={true}>
        <App />
      </FirebaseAppProvider>,
      document.getElementById("root")
    );
  } catch (e) {
    console.error("Could not initialize the Firebase SDK");
    console.error(e);
  }
}

main();
