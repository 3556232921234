import CardBarChart from "components/Cards/CardBarChart.js";
import { firestoreBase, OrderShares } from "constants.js";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFirestore } from "reactfire";
import Orders from "views/admin/Orders.js";

function Dashboard() {
  return (
    <div className="flex flex-wrap mt-4">
      <div className="w-full px-4">
        <SalesBreakdown />
      </div>
      <div className="w-full px-4">
        <Orders
          title="Verified Requests"
          status="verified"
          numRows={3}
          action={
            <Link
              to="/admin/orders?status=verified"
              role="button"
              className="py-2 px-4 rounded border-0 shadow uppercase font-bold text-xs text-white bg-violet-500 focus:outline-none hover:bg-violet-400 hover:shadow-md active:bg-violet-600"
            >
              More
            </Link>
          }
        />
      </div>
      <div className="w-full px-4">
        <Orders
          title="Needs Attention"
          status="error"
          numRows={3}
          action={
            <Link
              to="/admin/orders?status=error"
              role="button"
              className="py-2 px-4 rounded border-0 shadow uppercase font-bold text-xs text-white bg-violet-500 focus:outline-none hover:bg-violet-400 hover:shadow-md active:bg-violet-600"
            >
              More
            </Link>
          }
        />
      </div>
      <div className="w-full px-4">
        <Orders
          title="New Requests"
          status="new"
          numRows={3}
          action={
            <Link
              to="/admin/orders?status=new"
              role="button"
              className="py-2 px-4 rounded border-0 shadow uppercase font-bold text-xs text-white bg-violet-500 focus:outline-none hover:bg-violet-400 hover:shadow-md active:bg-violet-600"
            >
              More
            </Link>
          }
        />
      </div>
    </div>
  );
}

function SalesBreakdown() {
  const firestore = useFirestore();
  const [orders, setOrders] = useState([]);
  const [shareSales, setShareSales] = useState(
    Object.assign(
      {},
      ...Object.keys(OrderShares).map((shareSales) => ({ [shareSales]: 0 }))
    )
  );
  useEffect(() => {
    return firestore
      .doc(firestoreBase)
      .collection("orders")
      .where("status", "==", "fulfilled")
      .onSnapshot(
        { includeMetadataChanges: false },
        {
          next(snapshot) {
            setOrders(snapshot.docs);
          },
        }
      );
  }, [firestore]);

  useEffect(() => {
    setShareSales((ticketSales) => {
      const sales = {};
      for (const ticketType in ticketSales) {
        if (Object.hasOwnProperty.call(ticketSales, ticketType)) {
          sales[ticketType] = orders.reduce(
            (total, order) => total + (order.get(ticketType) ?? 0),
            0
          );
        }
      }
      return sales;
    });
  }, [orders]);
  return (
    <CardBarChart
      title="Total Shares Sales"
      subtitle="By Ticket Type"
      xlabel="Days"
      labels={["Day 1", "Day 2", "Day 3"]}
      ylabel="Tickets Sold"
      datasets={[
        {
          label: "VIP",
          backgroundColor: "#9FA8DA",
          borderColor: "#9FA8DA",
          data: [
            shareSales.num_day1_VIP_tickets,
            shareSales.num_day2_VIP_tickets,
            shareSales.num_day3_VIP_tickets,
          ],
          fill: false,
          barThickness: 16,
        },
        {
          label: "Class B",
          backgroundColor: "#D4E157",
          borderColor: "#D4E157",
          data: [
            shareSales.num_day1_classB_tickets,
            shareSales.num_day2_classB_tickets,
            shareSales.num_day3_classB_tickets,
          ],
          fill: false,
          barThickness: 16,
        },
        {
          label: "Class B Discount",
          backgroundColor: "#A1887F",
          borderColor: "#A1887F",
          data: [
            shareSales.num_day1_classC_tickets,
            shareSales.num_day2_classC_tickets,
            shareSales.num_day3_classC_tickets,
          ],
          fill: false,
          barThickness: 16,
        },
      ]}
    />
  );
}

export default Dashboard;
