import LoadingPage from "components/Loading/LoadingPage.js";
import PropTypes from "prop-types";
import { Suspense } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useSigninCheck } from "reactfire";

function PrivateRoute(props) {
  return (
    <Suspense fallback={<LoadingPage text="Logging In..." />}>
      <RenderIfSignedIn {...props} />
    </Suspense>
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

function RenderIfSignedIn({ component: Component, ...rest }) {
  const location = useLocation();
  const { data: signinResult } = useSigninCheck();
  if (!signinResult.signedIn) {
    return (
      <Redirect
        to={{
          pathname: "/auth/login",
          state: { from: location.pathname },
        }}
      />
    );
  }
  return <Component {...rest} />;
}

export default PrivateRoute;
