export const OrderStatus = {
  new: "New",
  ignored: "Ignored",
  verified: "Verified",
  error: "Has Issues",
  fulfilled: "Fulfilled",
};

export const OrderShares = {
  num_Silver_shares: "Silver Shares",
  num_Gold_shares: "Gold Shares",
  num_Platinum_shares: "Platinum Shares",
};

export const firestoreBase = `${process.env.REACT_APP_FIRESTORE_BASE_COLLECTION}/${process.env.REACT_APP_FIRESTORE_BASE_DOCUMENT}`;
