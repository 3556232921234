import classNames from "classnames";
import Card from "components/Cards/Card.js";
import LoadingTableCard from "components/Cards/LoadingTableCard.js";
import Cell from "components/Table/Cell.js";
import HeadingCell from "components/Table/HeadingCell.js";
import Table from "components/Table/Table.js";
import TablePagination from "components/Table/TablePagination.js";
import { firestoreBase, OrderShares, OrderStatus } from "constants.js";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React, { Suspense, useCallback, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useFirestore, useFirestoreCollection } from "reactfire";
import { insertIf } from "utils.js";

export default function Orders({ status, ...props }) {
  const location = useLocation();
  let statusQuery = status;
  if (statusQuery == null) {
    statusQuery = new URLSearchParams(location.search).get("status");
  }
  if (props.title == null) {
    props.title = "Orders";
    if (statusQuery) {
      props.title = `${OrderStatus[statusQuery]} Orders`;
    }
  }
  const headings = [
    "Customer",
    "Phone Number",
    "Price",
    "Shares",
    ...insertIf(statusQuery == null, "Status"),
    "Time",
  ];
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <Suspense
            fallback={
              <LoadingTableCard
                {...props}
                headings={headings}
                status={statusQuery}
              />
            }
          >
            <OrdersCard headings={headings} {...props} status={statusQuery} />
          </Suspense>
        </div>
      </div>
    </>
  );
}

Orders.defaultProps = {
  numRows: 10,
};

Orders.propTypes = {
  title: PropTypes.string,
  status: PropTypes.oneOf(["new", "verified", "ignored", "error", "fulfilled"]),
  numRows: PropTypes.number.isRequired,
  action: PropTypes.node,
};

function OrdersCard({ title, headings, status, numRows, action }) {
  const firestore = useFirestore();
  const query = useMemo(() => {
    let query = firestore.doc(firestoreBase).collection("orders");
    if (status != null) {
      query = query.where("status", "==", status);
    }
    query = query.orderBy("time", "desc");
    return query;
  }, [firestore, status]);
  const { data: collection } = useFirestoreCollection(query);

  const location = useLocation();
  const startPage =
    location.state?.page ?? (collection.docs.length > 0 ? 1 : 0);
  const [page, setPage] = useState(startPage);
  const numPages = Math.ceil(collection.docs.length / numRows);

  const history = useHistory();
  const nextPage = useCallback(
    () => setPage((page) => Math.min(page + 1, numPages)),
    [numPages]
  );
  const prevPage = useCallback(
    () => setPage((page) => Math.max(1, page - 1)),
    []
  );
  const goToPage = useCallback(
    (newPage) => setPage(Math.min(Math.max(1, newPage), numPages)),
    [numPages]
  );
  const handleClick = useCallback(
    (orderId, event) => {
      if (event.metaKey || event.ctrlKey) {
        const win = window.open(`/admin/orders/${orderId}`, "_blank");
        win?.focus();
      } else {
        history.push({
          pathname: `/admin/orders/${orderId}`,
          state: {
            from: {
              ...location,
              state: {
                ...location.state,
                page,
              },
            },
          },
        });
      }
    },
    [history, location, page]
  );
  return (
    <Card title={title} action={action}>
      <Table>
        <thead>
          <tr>
            {headings.map((heading) => (
              <HeadingCell key={heading}>{heading}</HeadingCell>
            ))}
          </tr>
        </thead>
        <tbody>
          {collection.docs
            .slice((page - 1) * numRows, page * numRows)
            .map((doc) => (
              <Order
                key={doc.id}
                doc={doc}
                includeStatus={status == null}
                onClick={handleClick}
              />
            ))}
        </tbody>
      </Table>
      <hr />
      <TablePagination
        current={page}
        total={numPages}
        onNext={nextPage}
        onPrev={prevPage}
        onJump={goToPage}
      />
    </Card>
  );
}

function Order({ doc, includeStatus, onClick }) {
  const order = doc.data();
  const handleClick = useCallback(
    (event) => onClick(doc.id, event),
    [doc.id, onClick]
  );
  return (
    <tr
      onClick={handleClick}
      className="hover:bg-blueGray-50 hover:cursor-pointer"
    >
      <Cell>{order.name}</Cell>
      <Cell>{order.phone}</Cell>
      <Cell>{order.price} SDG</Cell>
      <Cell>
        {Object.keys(OrderShares).map((ticketsClass) =>
          order[ticketsClass] > 0 ? (
            <React.Fragment key={ticketsClass}>
              <span>
                {order[ticketsClass]} &times; {OrderShares[ticketsClass]}
              </span>
              <br />
            </React.Fragment>
          ) : null
        )}
      </Cell>
      {includeStatus ? (
        <Cell>
          <i
            className={classNames("fas fa-circle mr-2", {
              "text-sky-400": order.status === "new",
              "text-gray-400": order.status === "ignored",
              "text-indigo-500 ": order.status === "verified",
              "text-emerald-500": order.status == "fulfilled",
              "text-red-500": order.status === "error",
            })}
          ></i>{" "}
          {OrderStatus[order.status]}
        </Cell>
      ) : null}
      <Cell>{format(order.time.toDate(), "ccc dd LLL hh:mm a (O)")}</Cell>
    </tr>
  );
}
