import classNames from "classnames";
import PropTypes from "prop-types";

function Header({ children }) {
  return (
    <div className="grid justify-items-center gap-y-4 gap-x-2 detailsHeader text-blueGray-600 ">
      {children}
    </div>
  );
}

function HeaderItem({ icon, children }) {
  return (
    <>
      <div className="w-full h-full">
        <i
          className={classNames(
            "text-lg text-center align-middle fas",
            icon,
            "w-full"
          )}
        />
      </div>
      <div className="w-full text-center">{children}</div>
    </>
  );
}
HeaderItem.propTypes = {
  icon: PropTypes.string.isRequired,
};

Header.Item = HeaderItem;

export default Header;
