import HeaderStats from "components/Headers/HeaderStats.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "views/admin/Dashboard.js";
import Orders from "views/admin/Orders.js";
import OrdersDetails from "views/admin/OrdersDetails/index.js";
import Transactions from "views/admin/Transactions.js";
import TransactionsUpload from "views/admin/TransactionsUpload.js";

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100 pb-12">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -mt-24">
          <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />
            <Route path="/admin/orders" exact component={Orders} />
            <Route path="/admin/orders/:id" component={OrdersDetails} />
            <Route path="/admin/transactions" exact component={Transactions} />
            <Route
              path="/admin/transactions/upload"
              exact
              component={TransactionsUpload}
            />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
        </div>
      </div>
    </>
  );
}
