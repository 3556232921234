import PrivateRoute from "components/Routes/PrivateRoute.js";
import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute path="/admin" component={Admin} />
        <Route path="/auth" component={Auth} />
        <Redirect from="*" to="/admin" />
      </Switch>
    </BrowserRouter>
  );
}
