// components
import CardStats from "components/Cards/CardStats.js";
import { addDays, startOfDay, subDays, startOfMonth } from "date-fns";
import React, { useEffect, useState } from "react";
import { useFirestore } from "reactfire";

export default function HeaderStats() {
  const [today, setToday] = useState(new Date());
  // re-render when the day changes
  useEffect(() => {
    const tomorrow = startOfDay(addDays(today, 1));
    const timeout = setTimeout(() => setToday(new Date()), tomorrow - today);
    return () => clearTimeout(timeout);
  }, [today]);
  return (
    <>
      {/* Header */}
      <div className="relative bg-gradient-to-br from-purple-600 to-sky-600 md:pt-32 pb-32 pt-12">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <NewOrdersCard today={today} />
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <FulfilledOrdersCard today={today} />
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <TicketSalesCard today={today} />
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <RevenueCard today={today} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function NewOrdersCard({ today }) {
  const query = useFirestore().collection("orders");
  const _startOfMonth = startOfMonth(today);
  const monthBefore = subDays(_startOfMonth, 30);
  return (
    <CardStats
      name="New Requests"
      description="From Last Month"
      icon="fa-shopping-cart"
      iconColor="bg-red-600"
      query={query}
      start={_startOfMonth}
      before={monthBefore}
    />
  );
}

function FulfilledOrdersCard({ today }) {
  const query = useFirestore()
    .collection("orders")
    .where("status", "==", "fulfilled");
  const _startOfMonth = startOfMonth(today);
  const monthBefore = subDays(_startOfMonth, 30);
  return (
    <CardStats
      name="Fulfilled Requests"
      description="From Last Month"
      icon="fa-check"
      iconColor="bg-green-600"
      query={query}
      start={_startOfMonth}
      before={monthBefore}
    />
  );
}

function TicketSalesCard({ today }) {
  const query = useFirestore()
    .collection("orders")
    .where("status", "==", "fulfilled");
  const _startOfMonth = startOfMonth(today);
  const monthBefore = subDays(_startOfMonth, 30);
  const calculateTickets = (docs) =>
    docs.reduce(
      (tickets, order) =>
        tickets +
        (order.get("num_day1_VIP_tickets") ?? 0) +
        (order.get("num_day1_classB_tickets") ?? 0) +
        (order.get("num_day1_classC_tickets") ?? 0) +
        (order.get("num_day2_VIP_tickets") ?? 0) +
        (order.get("num_day2_classB_tickets") ?? 0) +
        (order.get("num_day2_classC_tickets") ?? 0) +
        (order.get("num_day3_VIP_tickets") ?? 0) +
        (order.get("num_day3_classB_tickets") ?? 0) +
        (order.get("num_day3_classC_tickets") ?? 0),
      0
    );
  return (
    <CardStats
      name="Shares Sold"
      description="From Last Month"
      icon="fa-ticket-alt"
      iconColor="bg-amber-400"
      query={query}
      calculate={calculateTickets}
      start={_startOfMonth}
      before={monthBefore}
    />
  );
}

function RevenueCard({ today }) {
  const query = useFirestore()
    .collection("orders")
    .where("status", "==", "fulfilled");
  const _startOfMonth = startOfMonth(today);
  const monthBefore = subDays(_startOfMonth, 30);
  const calculateTickets = (docs) =>
    docs.reduce((revenue, order) => revenue + order.get("price"), 0);
  return (
    <CardStats
      name="Revenue (SDG)"
      description="From Last Month"
      icon="fa-dollar-sign"
      iconColor="bg-sky-600"
      query={query}
      calculate={calculateTickets}
      start={_startOfMonth}
      before={monthBefore}
    />
  );
}
