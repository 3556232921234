import classNames from "classnames";
import { firestoreBase } from "constants.js";
import React from "react";
import "react-gallery-carousel/dist/index.css";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
} from "react-router-dom";
import { useFirestore, useFirestoreDocData } from "reactfire";
import Customer from "views/admin/OrdersDetails/Customer.js";
import Order from "views/admin/OrdersDetails/Order.js";

function OrdersDetails() {
  const { id } = useParams();
  const orderRef = useFirestore()
    .doc(firestoreBase)
    .collection("orders")
    .doc(id);
  const { data: order } = useFirestoreDocData(orderRef);
  return (
    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
      <div className="grid grid-cols-2">
        <Tab pathname={`/admin/orders/${id}/order`}>Order</Tab>
        <Tab pathname={`/admin/orders/${id}/customer`}>Customer</Tab>
      </div>
      <div className="mt-4 px-8 pb-2">
        <Switch>
          <Route exact path="/admin/orders/:id/order">
            <Order orderRef={orderRef} order={order} />
          </Route>
          <Route exact path="/admin/orders/:id/customer">
            <Customer orderRef={orderRef} order={order} />
          </Route>
          <Redirect to={`/admin/orders/${id}/order`} />
        </Switch>
      </div>
    </div>
  );
}

function Tab({ children, pathname }) {
  const location = useLocation();
  const active = location.pathname.startsWith(pathname);
  return (
    <Link
      to={pathname}
      className={classNames("text-center py-2 uppercase text-sm", {
        "font-bold bg-white": active,
        "bg-gray-300 opacity-70": !active,
      })}
    >
      {children}
    </Link>
  );
}

export default OrdersDetails;
