import Carousel from "react-gallery-carousel";

const { default: Header } = require("views/admin/OrdersDetails/Header.js");

function Customer({ orderRef, order }) {
  const images = order.credentials_urls.map((url) => ({
    src: url,
  }));
  return (
    <>
      <Header>
        <Header.Item icon="fa-user">{order.name}</Header.Item>
        <Header.Item icon="fa-home">{order.address}</Header.Item>
        <Header.Item icon="fa-phone-alt">{order.call_phone}</Header.Item>
        <Header.Item icon="fa-comment-dots">{order.social_phone}</Header.Item>
        <Header.Item icon="fa-briefcase">
          {order.job}
          <br />
          {order.job_loc}
        </Header.Item>
        <Header.Item icon="fa-at">
          <a href={`mailto:${order.email}`}>{order.email}</a>
        </Header.Item>
      </Header>
      <hr className="my-4" />
      <h1 className="mb-4 text-center font-bold uppercase text-blueGray-500">
        Identification
      </h1>
      <Header>
        <Header.Item icon="fa-id-card">{order.cred_type}</Header.Item>
        <Header.Item icon="fa-qrcode">{order.cred_num}</Header.Item>
        <Header.Item icon="fa-map-marker-alt">{order.cred_loc}</Header.Item>
        <Header.Item icon="fa-calendar-times">{order.cred_exp}</Header.Item>
      </Header>
      {images.length > 0 ? (
        <div className="mt-8">
          <Carousel
            images={images}
            style={{ width: "100%", height: "32rem" }}
            isLoop={false}
            canAutoPlay={false}
            objectFit="contain"
          />
        </div>
      ) : (
        <p className="text-xs text-gray-400">
          The customer has not sent an image as of yet
        </p>
      )}
    </>
  );
}

export default Customer;
