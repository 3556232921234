import classNames from "classnames";
import { firestoreBase, OrderShares, OrderStatus } from "constants.js";
import { format as formatDate } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import { useFirestore } from "reactfire";
import Header from "views/admin/OrdersDetails/Header.js";

const errorLabels = {
  "transactions-not-found":
    "Some of the transactions could not be found in the latest PDF transactions log. Please check the transaction IDs carefully.",
  "amount-mismatch":
    "The transactions IDs were verified, but the total amount does not add up to the order's price.",
};

function Order({ orderRef, order }) {
  const images = order.imageUrls.map((url) => ({
    src: url,
  }));
  return (
    <>
      <Header>
        <Header.Item icon="fa-dollar-sign">SDG {order.price}</Header.Item>
        <Header.Item
          icon={classNames({
            "fa-circle": order.status === "new",
            "fa-eye-slash": order.status === "ignored",
            "fa-certificate": order.status === "verified",
            "fa-check": order.status === "fulfilled",
            "fa-exclamation-circle": order.status === "error",
          })}
        >
          {OrderStatus[order.status]}
        </Header.Item>
        <OrderId orderId={orderRef.id} />
        <Header.Item icon="fa-calendar">
          {formatDate(order.time.toDate(), "MMM, do yyyy")}
          <br />
          {formatDate(order.time.toDate(), "hh:mma")}
        </Header.Item>
      </Header>
      <div className="mt-4 flex flex-wrap text-sm gap-4 justify-center">
        {Object.keys(OrderShares).map((ticketClass) =>
          order[ticketClass] > 0 ? (
            <span
              key={ticketClass}
              className="text-white bg-violet-500 font-bold py-1 px-2 rounded"
            >
              {order[ticketClass]} × {OrderShares[ticketClass]}
            </span>
          ) : null
        )}
      </div>
      {order.status === "error" ? (
        <h6 className="text-center text-sm text-red-500">
          {errorLabels[order.errorCode]}
        </h6>
      ) : null}
      {["new", "error", "ignored"].indexOf(order.status) != -1 ? (
        <ManageStatus orderRef={orderRef} order={order} />
      ) : (
        <OrderTransactions transactions={order.transactions} />
      )}
      {images.length > 0 ? (
        <div className="mt-8">
          <Carousel
            images={images}
            style={{ width: "100%", height: "32rem" }}
            isLoop={false}
            canAutoPlay={false}
            objectFit="contain"
          />
        </div>
      ) : (
        <p className="text-xs text-gray-400">
          The customer has not sent an image as of yet
        </p>
      )}
    </>
  );
}

function OrderId({ orderId }) {
  const [clicked, setClicked] = useState(false);
  const onClick = async () => {
    await navigator.clipboard.writeText(orderId);
    setClicked(true);
  };
  useEffect(() => {
    if (clicked) {
      const timeout = setTimeout(() => {
        setClicked(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [clicked]);
  return (
    <Header.Item icon="fa-barcode">
      <div
        onClick={onClick}
        className="relative inline-block tooltip-parent"
        style={{ cursor: "pointer" }}
      >
        {orderId}
        <div className="tooltip p-2 rounded text-xs text-white bg-gray-500 shadow-sm">
          {clicked ? (
            <div>
              <i className="fas fa-check" /> Copied
            </div>
          ) : (
            <span>Click to Copy</span>
          )}
        </div>
      </div>
    </Header.Item>
  );
}

function OrderTransactions({ transactions }) {
  const txnIds = Object.keys(transactions);
  if (txnIds?.length > 0) {
    return (
      <div className="my-8">
        <div className="mt-4 flex flex-col gap-1 px-8">
          {txnIds.map((txnId) => {
            return (
              <OrderTransaction
                key={txnId}
                txnId={txnId}
                data={transactions[txnId]}
              />
            );
          })}
        </div>
      </div>
    );
  } else {
    return null;
  }
}

function OrderTransaction({ txnId, data }) {
  return (
    <div className="w-full flex justify-between text-sm">
      <i className="fas fa-money-check-alt mr-2 text-lg text-emerald-600"></i>
      <div>#{txnId}</div>
      <div>SDG {data.amount}</div>
      <div>{formatDate(data.time.toDate(), "yyyy-MM-dd")}</div>
    </div>
  );
}

function ManageStatus({ orderRef, order }) {
  const FieldValue = useFirestore.FieldValue;
  const firestore = useFirestore();
  const [error, setError] = useState();
  const [txnIds, setTxnIds] = useState(order.transactionNumbers);
  const [txnErrors, setTxnErrors] = useState({});
  const [processing, setProcessing] = useState(false);

  const handleChange = function (idx) {
    return function ({ target: { value } }) {
      const url = order.imageUrls[idx];
      setTxnIds((txnIds) => ({
        ...txnIds,
        [url]: value,
      }));
      setTxnErrors((txnErrors) => ({
        ...txnErrors,
        [url]: null,
      }));
    };
  };
  const handleVerify = useCallback(async () => {
    setProcessing(true);
    try {
      const errors = {};
      for (const url of order.imageUrls) {
        const txnId = txnIds[url];
        if (typeof txnId === "string" && txnId.trim().length > 0) {
          try {
            const doc = await firestore
              .doc(firestoreBase)
              .collection("transactions")
              .doc(txnId)
              .get();
            if (!doc.exists) {
              errors[url] = "This transaction has not been logged.";
            } else if (
              doc.get("orderId") != null &&
              doc.get("orderId") != orderRef.id
            ) {
              errors[url] = "This transaction has already been used.";
            } else {
            }
          } catch (err) {
            errors[url] = "Could not verify this transaction";
          }
        }
      }
      setTxnErrors(errors);
    } catch (err) {
      return setError(
        "Could not verify the transactions this time, try again."
      );
    } finally {
      setProcessing(false);
    }
  }, [firestore, orderRef.id, order.imageUrls, txnIds]);
  const handleSave = async () => {
    setProcessing(true);
    try {
      const transactionNumbers = {};
      for (const url of Object.keys(txnIds)) {
        const txnId = txnIds[url];
        if (typeof txnId === "string" && txnId.trim().length > 0) {
          transactionNumbers[url] = txnId.trim();
        }
      }
      await orderRef.update({ transactionNumbers });
    } finally {
      setProcessing(false);
    }
  };
  const handleIgnore = async () => {
    setProcessing(true);
    try {
      await orderRef.update({
        status: "ignored",
        ignoredAt: FieldValue.serverTimestamp(),
      });
    } finally {
      setProcessing(false);
    }
  };
  const handleUnignore = async () => {
    setProcessing(true);
    try {
      await orderRef.update({
        status: "new",
      });
    } finally {
      setProcessing(false);
    }
  };
  return (
    <>
      {order.status === "new" && order.imageUrls.length > 0 ? (
        <div className="mt-4 flex flex-col gap-2">
          {order.imageUrls.map((url, i) => (
            <TransactionField
              key={i}
              imageNum={i + 1}
              error={txnErrors[url]}
              saving={processing}
              defaultValue={txnIds[url]}
              onChange={handleChange(i)}
            />
          ))}
        </div>
      ) : null}
      <div className="flex mt-8">
        {error ? (
          <p className="text-sm text-red-600 m-0 p-0 mt-2 self-start">
            {error}
          </p>
        ) : null}
        {["new", "ignored"].indexOf(order.status) !== -1 ? (
          <button
            onClick={order.status !== "ignored" ? handleIgnore : handleUnignore}
            className={classNames(
              "py-2 px-4 rounded border-0 shadow uppercase font-bold text-xs",
              {
                "text-white bg-gray-300": processing,
                "text-white bg-red-500 focus:outline-none hover:bg-red-400 hover:shadow-md active:bg-red-600":
                  !processing,
              }
            )}
          >
            {order.status !== "ignored" ? "Ignore" : "Unignore"}
          </button>
        ) : null}
        <div className="flex-1"></div>
        {order.imageUrls.length > 0 ? (
          <button
            onClick={handleVerify}
            className={classNames(
              "mr-2 text-xs py-1 px-3 rounded border-2 transition",
              {
                "text-gray-300 border-gray-300 uppercase": processing,
                "text-sky-600 border-sky-600 uppercase hover:bg-sky-100 focus:outline-none active:bg-sky-200":
                  !processing,
              }
            )}
          >
            Check
          </button>
        ) : null}
        <button
          onClick={handleSave}
          className={classNames(
            "py-2 px-4 rounded border-0 shadow uppercase font-bold text-xs",
            {
              "text-white bg-gray-300": processing,
              "text-white bg-emerald-500 focus:outline-none hover:bg-emerald-400 hover:shadow-md active:bg-emerald-600":
                !processing,
            }
          )}
        >
          Save
        </button>
      </div>
    </>
  );
}

function TransactionField({ error, saving, imageNum, defaultValue, onChange }) {
  return (
    <div>
      <input
        type="text"
        className={classNames(
          "border-0 px-3 py-3 rounded shadow focus:outline-none w-full",
          "focus:ring ease-linear transition-all duration-150",
          "text-sm",
          {
            "ring ring-red-500 focus:ring-red-500 placeholder-red-300 text-red-600":
              error != null,
            "placeholder-blueGray-300 text-blueGray-600": error == null,
          },
          {
            "bg-white": !saving,
            "bg-trueGray-100": saving,
          }
        )}
        placeholder={`Transaction ID of Image #${imageNum}`}
        name="id"
        disabled={saving}
        onChange={onChange}
        defaultValue={defaultValue}
      />
      {error ? (
        <p className="text-xs text-red-600 m-0 p-0 mt-2 text-right">{error}</p>
      ) : null}
    </div>
  );
}

export default Order;
